import apiUrl from '../config/btpApi';
import axios from 'axios';
import errorHandling from './ErrorHandling';

const Forgot = {
  forgetPassword: async (data) => {
    try {
      const response = await axios.post(apiUrl.forget, data);
      return response.data;
    } catch (err) {
      errorHandling.handleErrors(err);
    }
  },
  verifyOtp: async (data) => {
    try {
      const response = await axios.post(apiUrl.verifyOtp, data);
      return response.data;
    } catch (err) {
      errorHandling.handleErrors(err?.response?.data?.message);
      throw err;
    }
  },
  resetPassword: async (data) => {
    try {
      const response = await axios.post(apiUrl.resetPassword, data);
      return response.data;
    } catch (err) {
      errorHandling.handleErrors(err?.response?.data?.message);
      throw err;
    }
  },
};

export default Forgot;
