import axios from "axios";
import apiUrl from "../config/btpApi";
import { MumfyAuth } from "./MumfyAuth";
import errorHandling from "./ErrorHandling";
import Cookies from "js-cookie";
import { BTPFunctions } from "./BTPFunctions";

const Login = {
  userLogin: async (data) => {
    try {
      const response = await MumfyAuth.fetchData(
        apiUrl.loginUrl,
        "post",
        { "client-platform": "web" },
        data
      );
      Cookies.set("authToken", response.data.token);

      Cookies.set("userRoles", response.data.user.roles);
      Cookies.set("userId", response.data.user.sk_user_id);
      Cookies.set("path", BTPFunctions.getUserAccess(response.data.user.roles));
      return response;
    } catch (err) {
      errorHandling.handleErrors(
        err,
        err.response && err.response.status ? err.response.status : "500"
      );
    }
  },

  loginSSO: async (data) => {
    try {
      const response = await axios.post(apiUrl.loginSSOUrl, data, {
        headers: {
          "x-api-key": process.env.REACT_APP_API_KEY
        }
      });
      Cookies.set("authToken", response.data.token);

      Cookies.set("userRoles", response.data.user.roles);
      Cookies.set("userId", response.data.user.sk_user_id);

      Cookies.set("path", BTPFunctions.getUserAccess(response.data.user.role));
      return response;
    } catch (err) {
      return err.response.data;
    }
  },

  requestPhoneOtp: async (data) => {
    try {
      const response = await MumfyAuth.fetchData(
        apiUrl.requestPhoneOtp,
        "post",
        { "client-platform": "web" },
        data
      );
      return response;
    } catch (err) {
      errorHandling.handleErrors(err, err?.response?.data?.message || err?.message);
    }
  },

  verifyPhoneOtp: async (data) => {
    try {
      const response = await MumfyAuth.fetchData(
        apiUrl.verifyPhoneOtp,
        "post",
        { "client-platform": "web" },
        data
      );
      Cookies.set("authToken", response.data.token);
      console.log("response", response);
      Cookies.set("userRoles", response.data.user.roles);
      Cookies.set("userId", response.data.user.sk_user_id);
      console.log("response.data.user.roles", response.data);
      Cookies.set("path", BTPFunctions.getUserAccess(response.data.user.roles));
      return response;
    } catch (err) {
      errorHandling.handleErrors(err, err?.response?.data?.message || err?.message);
    }
  }
};
export default Login;
