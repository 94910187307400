import React, { createContext, useState, useContext } from 'react';
import { customDate } from '../Components/CustomDate';
import propTypes from 'prop-types';
const DateChange = createContext();
function DateChangeContext({ children }) {
  DateChangeContext.propTypes = {
    children: propTypes.any,
  };
  const [selectionRange, setSelectionRange] = useState(
    customDate.getDefaultDateRange(),
  );
  return (
    <DateChange.Provider value={{ selectionRange, setSelectionRange }}>
      {children}
    </DateChange.Provider>
  );
}

const DateRangeState = () => useContext(DateChange);

export { DateChangeContext, DateChange, DateRangeState };
