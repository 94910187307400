import moment from 'moment';

const customDate = {
  getUtcStartDate(date) {
    return new moment(date, 'YYYY/MM/DDTHH:mm').utc().format();
  },

  getUtcEndDate(date) {
    const utcEndOfDay = new moment(date).endOf('day').utc();
    return utcEndOfDay.format();
  },

  getAllDate(date) {
    const startDate = moment(date, 'ddd MMM DD YYYY HH:mm:ss ZZ');
    const year = startDate.year();
    return year;
  },

  getDefaultDateRange(frequency) {
    let startOfTheDay = moment().startOf('day').subtract(1, 'days').toDate();
    const endOfTheDay = moment().endOf('day').toDate();
    if (frequency === 'allData') {
      startOfTheDay = moment(new Date(null)).toDate();
    }
    if (frequency === 'week') {
      startOfTheDay = moment().startOf('day').subtract(7, 'days').toDate();
    }
    const defaultDate = {
      startDate: startOfTheDay,
      endDate: endOfTheDay,
      key: 'selection',
    };

    return defaultDate;
  },

  getOneWeekRange() {
    const startOfTheDay = moment().startOf('day').subtract(7, 'days').toDate();
    const endOfTheDay = moment().endOf('day').toDate();
    const oneWeekDate = {
      startDate: startOfTheDay,
      endDate: endOfTheDay,
      key: 'selection',
    };
    return oneWeekDate;
  },
};
export { customDate };
