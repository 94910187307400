import React, { useState, useContext } from "react";
import "./Chat.css";
import { Button, Form, InputGroup, Accordion } from "react-bootstrap";
import ChatAPI from "../../services/Chat";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser, faArrowUp } from "@fortawesome/free-solid-svg-icons";
import Citation from "./Citation";
import { ProfileContext } from "../../contexts/ProfileContext";
import Markdown from "react-markdown";

function Chat() {
  const [messages, setMessages] = useState([]);
  const [inputText, setInputText] = useState("");
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [citationModal, setCitationModal] = useState(false);
  const [citations, setCitations] = useState([]);
  const [currentMessage, setCurrentMessage] = useState("");
  const [activeIndex, setActiveIndex] = useState(null);
  const { logout, profile, login } = useContext(ProfileContext);

  const handleInputChange = (event) => {
    setInputText(event.target.value);
  };

  const sendChat = async (messages) => {
    const data = { messages };
    try {
      const result = await ChatAPI.createChat(data);
      const reader = await result.body?.getReader();
      let done = false;
      let content = "";
      let context = null;
      while (!done && reader) {
        const { value, done: d } = await reader.read();
        done = d;
        if (value) {
          const chunk = new TextDecoder().decode(value);
          const chunkText = chunk.replaceAll("data: ", "").split("\n\n");
          chunkText.map((chunckItem) => {
            if (!chunckItem) return;
            const jsonChunk = JSON.parse(chunckItem);

            if (jsonChunk.context) {
              context = jsonChunk.context;
            }
            if (jsonChunk.content) {
              content += jsonChunk.content;
              content.replace(/\[doc(\d+)\]/g, "[$1]");
              setCurrentMessage(content);
            }
          });
        }
      }
      setCurrentMessage(null);
      const updatedContent = content.replace(/\[doc(\d+)\]/g, "[$1]");
      setMessages((prevMessages) => [
        ...prevMessages,
        {
          content: updatedContent,
          role: "assistant",
          sourceDocuments: context?.citations || []
        }
      ]);
    } catch (error) {
      console.error("Error sending chat:", error);
    }
    setIsButtonDisabled(false);
  };

  const handleSendMessage = async (event) => {
    event.preventDefault();
    setIsButtonDisabled(true);
    if (inputText.trim() !== "") {
      setMessages((prevMessages) => [...prevMessages, { content: inputText, role: "user" }]);
      const chatMessages = messages.concat([{ content: inputText, role: "user" }]);
      setInputText("");
      await sendChat(chatMessages);
    }
  };

  const handleKeyDown = async (event) => {
    if (event.key === "Enter" && !event.shiftKey) {
      event.preventDefault();
      setIsButtonDisabled(true);
      if (inputText.trim() !== "") {
        setMessages((prevMessages) => [...prevMessages, { content: inputText, role: "user" }]);
        const chatMessages = messages.concat([{ content: inputText, role: "user" }]);
        setInputText("");
        await sendChat(chatMessages);
      }
    }
  };

  const openCitationModal = (event, citations) => {
    event.preventDefault();
    setCitationModal(true);
    setCitations(citations);
  };

  return (
    <div className="chat-container">
      <div className="chat-messages">
        {messages.map((message, index) => {
          return (
            <div key={index} className={`message ${message.role}`}>
              <div className="chat-message">
                {message.role === "user" ? (
                  <img className="avatar" width={70} src={profile?.profile_pic} alt="user" />
                ) : (
                  <img
                    className="maya-image"
                    src={process.env.PUBLIC_URL + "/images/riya_chatbot.svg"}
                    alt="riya"
                  />
                )}
                <Markdown>{message.content}</Markdown>

                {message?.sourceDocuments?.length > 0 && /\[\d+\]/.test(message.content) ? (
                  <div>
                    <br />{" "}
                    <Citation
                      citations={message.sourceDocuments}
                      content={message.content}
                      index={index}
                      activeIndex={activeIndex}
                      setActiveIndex={setActiveIndex}
                    />
                  </div>
                ) : null}
              </div>
            </div>
          );
        })}
        {currentMessage ? (
          <>
            <img
              className="maya-image"
              src={process.env.PUBLIC_URL + "/images/riya_chatbot.svg"}
              alt="riya"
            />
            {currentMessage}
          </>
        ) : null}
      </div>
      <Form>
        <div className="input-container">
          <InputGroup className="mb-3">
            <Form.Control
              className="form-control"
              onChange={handleInputChange}
              type="text"
              value={inputText}
              onKeyDown={handleKeyDown}
            />
            <Button
              variant="btn btn-outline-primary"
              disabled={isButtonDisabled}
              onClick={handleSendMessage}
            >
              <FontAwesomeIcon
                variant="outline-secondary"
                icon={faArrowUp}
                className="fs-6 p-0 m-0"
              ></FontAwesomeIcon>
            </Button>
          </InputGroup>
        </div>
      </Form>
    </div>
  );
}

export default Chat;
