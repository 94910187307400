import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import FormGroup from "react-bootstrap/FormGroup";
import { Row, Col } from "react-bootstrap";
import Profile from "../../../services/Profile";
import { useQueryClient } from "@tanstack/react-query";
import propTypes from "prop-types";
import Select from "react-select";
import roles from "../../../config/roles.json";
import { checkIsAdmin } from "../../../Components/GetUserAccess";
import { BTPFunctions } from "../../../services/BTPFunctions";

function AddUserForm(props) {
  AddUserForm.propTypes = {
    onHide: propTypes.func
  };
  const [password, setPassword] = useState("");
  const [fullName, setFullName] = useState("");
  const [rePassword, setRePassword] = useState("");
  const [phone, setPhone] = useState("");
  const [stage, setStage] = useState("");
  const [gender, setGender] = useState("");
  const [userRole, setUserRole] = useState({
    value: "internal",
    label: "Internal User"
  });
  const queryClient = useQueryClient();
  const genderArr = [
    { value: "Male", label: "Male" },
    { value: "Female", label: "Female" }
  ];
  const handleValidations = () => {
    if (password === rePassword && password !== "") {
      return true;
    }
  };

  const handleUserEmailChange = (event) => {
    setUserEmail(event.target.value);
  };

  const handleUserRoleChange = (selectedOption) => {
    setUserRole(selectedOption);
  };

  const handleSubmit = async () => {
    const check = handleValidations();
    if (check) {
      const response = await Profile.registerUser({
        full_name: fullName,
        phone,
        roles: userRole.value
      });
      if (response.status === 200) {
        queryClient.invalidateQueries("internalUserList");
        setFullName("");
        setPhone("");
        props.onHide();
      }
    }
  };

  return (
    <Modal {...props}>
      <Modal.Body>
        <Modal.Title id="contained-modal-title-vcenter">Add User</Modal.Title>
        <Form>
          <FormGroup className="mt-3">
            <FloatingLabel for="fullName">Full Name</FloatingLabel>
            <Form.Control
              type="text"
              id="fullName"
              className="mt-1"
              value={fullName}
              onChange={(e) => setFullName(e.target.value)}
              required
            />
          </FormGroup>
          <FormGroup className="mt-3">
            <FloatingLabel for="phone">Phone Number</FloatingLabel>
            <Form.Control
              type="email"
              id="phone"
              className="mt-1"
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
              required
            />
            {/* <Form.Control.Feedback type="invalid">
              Please provide a valid email.
            </Form.Control.Feedback> */}
          </FormGroup>
          {checkIsAdmin() ? (
            <FormGroup className="mt-3">
              <FloatingLabel for="userRole">User Role</FloatingLabel>
              <Select
                isClearable
                options={roles}
                value={userRole}
                onChange={handleUserRoleChange}
                isSearchable={true}
                className="mt-1"
                placeholder="Select User Role"
              />
            </FormGroup>
          ) : (
            <></>
          )}
        </Form>
        <span className="d-flex justify-content-end mt-4">
          <Button variant="outline-secondary" onClick={props.onHide}>
            Cancel
          </Button>
          <Button variant="outline-primary" className="ms-3" onClick={handleSubmit}>
            Add
          </Button>
        </span>
      </Modal.Body>
    </Modal>
  );
}

export default AddUserForm;
