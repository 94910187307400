import React, { Fragment, useContext, useState } from "react";
import Cookies from "js-cookie";
import { Dropdown } from "react-bootstrap";
import "react-toastify/dist/ReactToastify.css";
import UserSetting from "./UserSetting";
import { ProfileContext } from "../contexts/ProfileContext";
import AddUserForm from "../PortalPages/Users/UserTable/AddUserForm";
import { checkIsAdmin } from "./GetUserAccess";
import { Button } from "react-bootstrap";
import Profile from "../services/Profile";
import { BTPFunctions } from "../services/BTPFunctions";
import { useHistory } from "react-router-dom";

export default function UserBox() {
  const { logout, profile, login } = useContext(ProfileContext);
  const [modalShow, setModalShow] = useState(false);
  const [modalSetting, setModalSetting] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const navigate = useHistory();

  const handleLogout = () => {
    logout();
    Cookies.remove("authToken");
    Cookies.remove("userRoles");
    window.location.reload();
  };
  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };
  const closeDropdown = () => {
    setDropdownOpen(false);
  };

  const switchBackToPreviousAccount = async () => {
    const originalUserId = Cookies.get("originalUserId");
    const response = await Profile.switchBackLogin({
      userId: Cookies.get("originalUserId")
    });
    Cookies.set("authToken", response.data.token);
    Cookies.set("userRoles", response.data.roles);
    Cookies.set("userId", originalUserId);
    Cookies.set("path", BTPFunctions.getUserAccess(response.data.roles));
    const accessToken = Cookies.get("authToken");
    await login(accessToken);
    const path = Cookies.get("path");
    if (path !== "") {
      navigate.push(path);
    } else setError("You are not authorized");
    Cookies.remove("impersonatedUser");
    Cookies.remove("originalUserId");
  };

  return (
    <Fragment>
      <div className="header-btn-lg pe-0">
        <div className="widget-content p-0">
          <div className="widget-content-wrapper">
            <div className="app-header-right">
              {Cookies.get("impersonatedUser") === "true" ? (
                <Button
                  className="login-original-account"
                  onClick={switchBackToPreviousAccount}
                  variant="btn btn-outline-primary"
                >
                  Back to Original Account
                </Button>
              ) : null}{" "}
            </div>
            <div className="widget-content-left">
              <Dropdown isOpen={dropdownOpen} toggle={toggleDropdown}>
                <Dropdown.Toggle
                  variant="white"
                  id="dropdown-basic"
                  className="d-flex header-user-dropdow"
                >
                  <div className="widget-content-right me-3 header-user-info">
                    {profile ? <div className="widget-heading">{profile?.full_name}</div> : null}
                    <div className="widget-subheading">
                      {profile && profile?.roles?.length > 0
                        ? profile.roles.map((role) => {
                            return role;
                          })
                        : null}
                    </div>
                  </div>
                  <img className="avatar" width={30} alt="" src={profile?.profile_pic} />
                </Dropdown.Toggle>
                <Dropdown.Menu end className="rm-pointers dropdown-menu">
                  <Dropdown.Item
                    onClick={() => {
                      setModalShow(true);
                      closeDropdown();
                    }}
                    className={`${checkIsAdmin() ? "" : "d-none"}`}
                  >
                    Add User
                  </Dropdown.Item>
                  <Dropdown.Item
                    onClick={() => {
                      setModalSetting(true);
                      closeDropdown();
                    }}
                  >
                    Settings
                  </Dropdown.Item>

                  <Dropdown.Item
                    variant="danger"
                    onClick={() => {
                      handleLogout();
                      closeDropdown();
                    }}
                  >
                    Log Out
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
              <AddUserForm show={modalShow} onHide={() => setModalShow(false)} />
              <UserSetting show={modalSetting} onHide={() => setModalSetting(false)} />
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
}
