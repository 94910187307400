import Cookies from "js-cookie";
const checkIsAdmin = () => {
  const roles = Cookies.get("userRoles");
  return roles?.includes("admin");
};
const checkIsInternal = () => {
  const roles = Cookies.get("userRoles");
  return roles?.includes("internal");
};
const getUserRole = () => {
  const roles = Cookies.get("userRoles");
  return roles;
};

export { checkIsAdmin, getUserRole, checkIsInternal };
