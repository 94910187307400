import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import FormGroup from "react-bootstrap/FormGroup";
import Profile from "../services/Profile";
import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-toastify";
import propTypes from "prop-types";

function UserSetting(props) {
  UserSetting.propTypes = {
    onHide: propTypes.func
  };
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [reCheck, setReCheck] = useState("");

  const handleOldPassword = (event) => {
    setOldPassword(event.target.value);
  };

  const handleNewPassword = (event) => {
    setNewPassword(event.target.value);
  };

  const handleReCheck = (event) => {
    setReCheck(event.target.value);
  };

  const handleSubmit = async () => {
    if (newPassword === reCheck) {
      const data = await Profile.updatePassword({
        oldPassword,
        newPassword
      });
      if (data.status === "success") {
        props.onHide();
        toast.success("Password Changed Successfully!");
      }
    }
    setOldPassword("");
    setNewPassword("");
    setReCheck("");
  };

  return (
    <Modal {...props}>
      {/* <Modal.Header closeButton>
      </Modal.Header> */}
      <Modal.Body>
        <Modal.Title id="contained-modal-title-vcenter">Settings</Modal.Title>
        <Form>
          <FormGroup className="mt-3">
            <FloatingLabel for="oldPassword">Enter Old Password</FloatingLabel>
            <Form.Control
              type="password"
              id="userOldPassword"
              value={oldPassword}
              onChange={handleOldPassword}
              required
            />
          </FormGroup>
          <FormGroup className="mt-3">
            <FloatingLabel for="newPassword">Enter New Password</FloatingLabel>
            <Form.Control
              type="password"
              id="userNewPassword"
              value={newPassword}
              onChange={handleNewPassword}
              required
            />
          </FormGroup>
          <FormGroup className="mt-3">
            <FloatingLabel for="newPassword">Re-enter New Password</FloatingLabel>
            <Form.Control
              type="password"
              id="userRecheckNewPassword"
              value={reCheck}
              onChange={handleReCheck}
              required
            ></Form.Control>
          </FormGroup>
        </Form>
        <span className="d-flex justify-content-end mt-3">
          <Button variant="outline-secondary" onClick={props.onHide}>
            Cancel
          </Button>
          {oldPassword !== "" && newPassword !== "" && reCheck !== "" ? (
            <Button variant="outline-primary" onClick={handleSubmit}>
              Save
            </Button>
          ) : (
            <Button variant="outline-primary" className="ms-3" onClick={handleSubmit} disabled>
              Save
            </Button>
          )}
        </span>
      </Modal.Body>
    </Modal>
  );
}

export default UserSetting;
