import apiUrl from "../config/btpApi";
import { MumfyAuth } from "./MumfyAuth";
import errorHandling from "./ErrorHandling";
import axios from "axios";
import Cookies from "js-cookie";

const ChatAPI = {
  createChat: async (data) => {
    try {
      //const response = await MumfyAuth.fetchData(apiUrl.chat + "api/ai/v1/chat", method, {}, data);
      // const response = await fetch(apiUrl.chat + "api/ai/v1/chat", data, {
      //   headers: { accesstoken: Cookies.get("authToken"), responseType: "stream" }
      // });
      const options = {
        method: "POST",
        headers: { "Content-Type": "application/json", accesstoken: Cookies.get("authToken") },
        body: JSON.stringify(data)
      };

      const result = await fetch(apiUrl.chat, options);
      return result;
    } catch (err) {
      errorHandling.handleErrors(err, err?.response?.status);
    }
  }
};

export default ChatAPI;
