import React from 'react';
const Spinner = () => (
  <div className="d-flex justify-content-center spinner-screen">
    <div className="spinner" role="status">
      <span className="">
        {' '}
        <img
          className="spinner-image"
          src={process.env.PUBLIC_URL + '/images/spinner.svg'}
          alt="image"
        />
      </span>
    </div>
  </div>
);
export default Spinner;
