import axios from 'axios';
import Cookies from 'js-cookie';

const MumfyAuth = {
  async fetchData(url, method, headers, data) {
    let headerInfo = {};
    headerInfo.accesstoken = Cookies.get('authToken');
    if (headers) {
      headerInfo = { ...headerInfo, ...headers };
    }

    let response = {};
    if (data) {
      if (data.params) {
        response = await axios[method](url, {
          params: data.params,
          headers: headerInfo,
        });
      } else if (method === 'delete') {
        response = await axios[method](url, {
          headers: headerInfo,
          data,
        });
      } else {
        response = await axios[method](url, data, {
          headers: headerInfo,
        });
      }
    } else {
      response = await axios[method](url, {
        headers: headerInfo,
      });
    }
    return response;
  },
};

export { MumfyAuth };
