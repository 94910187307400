import React from "react";
import ReactDOM from "react-dom";
import { Route, HashRouter, Switch } from "react-router-dom";

// Import registerServiceWorker from './registerServiceWorker';
import { unregister } from "./registerServiceWorker";
import "./assets/base.css";
import Main from "./PortalPages/Main";
import { ProfileProvider } from "./contexts/ProfileContext";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import Login from "./PortalPages/Login";
import { ToastContainer } from "react-toastify";
import { DateChangeContext } from "./services/DateChangeContext";
import "react-datepicker/dist/react-datepicker.css";

const rootElement = document.getElementById("root");
const queryClient = new QueryClient();

const renderApp = (Component) => {
  ReactDOM.render(
    <DateChangeContext>
      <ProfileProvider>
        <QueryClientProvider client={queryClient}>
          <HashRouter>
            <Switch>
              <Route path="/login" component={Login} />
              <Route path="/" component={Component} />
            </Switch>
          </HashRouter>
        </QueryClientProvider>
        <ToastContainer />
      </ProfileProvider>
    </DateChangeContext>,
    rootElement
  );
};

renderApp(Main);

/*
 * If (module.hot) {
 *   module.hot.accept('./PortalPages/Main', () => {
 *     const NextApp = require('./PortalPages/Main').default;
 *     renderApp(NextApp);
 *   });
 * }
 */
unregister();

// RegisterServiceWorker();
