import apiUrl from "../config/btpApi";
import { MumfyAuth } from "./MumfyAuth";
import errorHandling from "./ErrorHandling";
import Cookies from "js-cookie";
import { getUserRole } from "../Components/GetUserAccess";

const Profile = {
  getUserDetails: async () => {
    try {
      const response = await MumfyAuth.fetchData(apiUrl.profileUrl, "get");
      return response;
    } catch (err) {
      errorHandling.handleErrors(err, err.response.status);
    }
  },

  getOtherUserDetails: async (userId) => {
    try {
      const response = await MumfyAuth.fetchData(
        apiUrl.otherUsersProfile,
        "post",
        {},
        { id: userId }
      );
      return response.data;
    } catch (err) {
      errorHandling.handleErrors(err, err.response.status);
    }
  },

  getUsers: async (page, queryData) => {
    const queryParams = {
      page: page.page,
      limit: page.limit
    };
    let query = "?page=" + (queryParams.page + 1) + "&limit=" + queryParams.limit;
    if (getUserRole()?.includes("internal")) {
      query += "&role=internal";
    }
    if (queryData.createdAt) {
      query += "&startDate=" + queryData.createdAt.$gt + "&endDate=" + queryData.createdAt.$lt;
    }
    if (queryData.name) {
      query += "&name=" + queryData.name;
    }
    if (queryData.phone) {
      let phone = queryData.phone;
      if (phone.startsWith("+")) {
        phone = queryData.phone.slice(1);
      }
      query += "&phone=" + phone;
    }
    if (queryData.role) {
      query += "&role=" + queryData.role;
    }
    if (queryData.stage) {
      query += "&stage=" + queryData.stage;
    }
    if (queryData.pinCode) {
      query += "&pinCode=" + queryData.pinCode;
    }
    try {
      const response = await MumfyAuth.fetchData(apiUrl.searchUrl + query, "get");
      return response.data;
    } catch (err) {
      errorHandling.handleErrors(err, err?.response?.status);
    }
  },

  getUserByUserId: async (id) => {
    try {
      const response = await MumfyAuth.fetchData(apiUrl.getUser + "?userId=" + id, "get");
      return response.data;
    } catch (err) {
      errorHandling.handleErrors(err, err.response.status);
    }
  },

  getInternalUser: async (id) => {
    try {
      const response = await MumfyAuth.fetchData(
        apiUrl.createInternalUserUrl + "?userId=" + id,
        "get"
      );
      return response.data;
    } catch (err) {
      errorHandling.handleErrors(err, err.response.status);
    }
  },

  getInpersonatelUser: async (id, type) => {
    try {
      const response = await MumfyAuth.fetchData(apiUrl.impersonateUserUrl, "post", {}, id, type);
      return response.data;
    } catch (err) {
      errorHandling.handleErrors(
        err?.response?.data?.message || "User does not have exchange token"
      );
    }
  },

  getCreatedBy: async (id) => {
    try {
      const response = await MumfyAuth.fetchData(
        apiUrl.createdByUserDetail + "?userId=" + id,
        "get"
      );
      return response;
    } catch (err) {
      // ErrorHandling.handleErrors(err, err.response.status);
    }
  },

  updateUserRole: async (data) => {
    //
    try {
      const response = await MumfyAuth.fetchData(apiUrl.updateUserRole, "patch", {}, data);
      return response;
    } catch (err) {
      errorHandling.handleErrors(err, err?.response?.status);
    }
  },

  updatePassword: async (password) => {
    try {
      const response = await MumfyAuth.fetchData(apiUrl.updatePassword, "post", {}, password);
      return response;
    } catch (err) {
      errorHandling.handleErrors(err, err.response.status);
    }
  },

  deleteUser: async (id) => {
    try {
      const response = await MumfyAuth.fetchData(apiUrl.deleteUserUrl, "post", {}, id);
      return response;
    } catch (err) {
      errorHandling.handleErrors(err, err.response.status);
    }
  },

  registerUser: async (data) => {
    try {
      const response = await MumfyAuth.fetchData(apiUrl.registerUrl, "post", {}, data);
      return response;
    } catch (err) {
      errorHandling.handleErrors(err, err?.response?.status);
    }
  },

  deCodeUtm: async (data) => {
    try {
      const response = await MumfyAuth.fetchData(apiUrl.utmDecodeUrl, "post", {}, data);
      return response.data;
    } catch (err) {
      errorHandling.handleErrors(err, err.response.status);
    }
  },

  updateInternalUser: async (data) => {
    //
    try {
      const response = await MumfyAuth.fetchData(apiUrl.updateInternalUser, "patch", {}, data);
      return response;
    } catch (err) {
      errorHandling.handleErrors(err, err.response.status);
    }
  },

  deleteInternalUser: async (data) => {
    try {
      const response = await MumfyAuth.fetchData(apiUrl.deleteInternalUserUrl, "delete", {}, data);
      return response;
    } catch (err) {
      errorHandling.handleErrors(err, err.response.status);
    }
  },

  impersonateLogin: async (data) => {
    try {
      const response = await MumfyAuth.fetchData(apiUrl.impersonateLogin, "post", {}, data);
      return response;
    } catch (err) {
      errorHandling.handleErrors(err, err?.response?.status);
    }
  },

  switchBackLogin: async (data) => {
    try {
      const response = await MumfyAuth.fetchData(apiUrl.switchBackLogin, "post", {}, data);
      return response;
    } catch (err) {
      errorHandling.handleErrors(err, err?.response?.status);
    }
  }
};

export default Profile;
